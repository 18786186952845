@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Exan";
  src: url("Exan-Regular.otf");
}

@font-face {
  font-family: "Mylodon";
  src: url("Mylodon.otf");
}

.App {
  text-align: center;
  background-image: url('background.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.canvas {
  z-index: -1;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  min-height: 10vh;
  font-size: calc(10px + 2vmin);
  color: black;
  font-family: "Exan";
}


.App-header2 {
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(5px + 1vmin);
  color: white;
  font-family: "Exan";
}

.App-link {
  color: #61dafb;
}

.container {
  max-width: 60%;
  margin: 2px auto;
  background-color: #FFA500;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  font-family: "Mylodon";
  font-size: calc(5px + 1vmin);
  color: #fff;
}

.container div {
  margin-bottom: 15px;
}

label {
  font-weight: bold;
}


.strength-0 {
  background-color: rgb(121, 42, 42);
}

.strength-1 {
  background-color: rgb(139, 91, 2);
}

.strength-2 {
  background-color: rgb(106, 149, 40);
}

.strength-3 {
  background-color: rgb(86, 116, 25);
}

.strength-4 {
  background-color: rgb(2, 49, 2);
}

.password-strength-bar {
  margin: 4px 1px;
  height: 10px;
  transition: width 0.3s ease-in-out;
}

.text-strength-0 {
  color: red;
  font-weight: bold;
}

.text-strength-1 {
  color: rgb(139, 91, 2);
  font-weight: bold;
}

.text-strength-2 {
  color: rgb(112, 162, 11);
  font-weight: bold;
}

.text-strength-3 {
  color: rgb(80, 122, 18);
  font-weight: bold;
}

.text-strength-4 {
  color: rgb(2, 49, 2);
  font-weight: bold;
}

.BackgroundVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}